.department-intro-container {
    height: 100vh;
    display: flex;
    padding: 0;
    margin: 0;
}

.department-intro-image {
    width: 50%;
    height: 100%;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.department-intro-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.department-intro-content {
    width: 50%;
    height: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    box-sizing: border-box;
}

.department-intro-content h2 {
    font-size: 2rem;
    line-height: 1.5;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.highlight {
    font-weight: bold;
}

.highlight-purple {
    color: #6B46C1;
}

.department-intro-content p {
    font-size: 1rem;
    margin: 1rem 0;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.5;
}

.numbers-section {
    display: flex;
    margin-top: 1.5rem;
}

.number-box {
    text-align: center;
    margin-right: 1.5rem;
}

.number-box h3 {
    font-size: 1.5rem;
    font-weight: bold;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.number-box p {
    font-size: 1rem;
    color: #6c757d;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* 移动端响应式布局 */
@media screen and (max-width: 768px) {
    .department-intro-container {
        height: auto;
        flex-direction: column;
        padding: 20px;
    }

    .department-intro-image {
        width: 100%;
        height: auto;
        margin-bottom: 30px;
        margin-top: 60px;
    }

    .department-intro-image img {
        height: auto;
        max-height: 400px;
        border-radius: 8px;
    }

    .department-intro-content {
        width: 100%;
        height: auto;
        text-align: center;
        align-items: center;
    }

    /* 调整部门介绍网格布局 */
    .department-sections-container > div {
        grid-template-columns: 1fr !important;
        padding: 0 20px !important;
    }

    /* 调整部门介绍图片容器 */
    .department-sections-container > div > div {
        padding: 10px !important;
    }

    /* 调整部门介绍图片 */
    .department-sections-container > div > div > div:first-child img {
        width: 100% !important;
        height: 200px !important;
        object-fit: cover !important;
        border-radius: 8px !important;
    }

    /* 减少部门介绍图片和文字之间的距离 */
    .department-sections-container > div > div > div:first-child {
        margin-bottom: 20px !important;
    }
}
