@import url('https://fonts.googleapis.com/css2?family=Silkscreen&display=swap');
@import url("https://192960944.r.cdn36.com/chinesefonts2/packages/ysbth/dist/优设标题黑/result.css");
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zhi+Mang+Xing&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Serif+SC:wght@200..900&family=ZCOOL+XiaoWei&display=swap');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.zhi-mang-xing-regular {
  font-family: "Zhi Mang Xing", cursive;
  font-weight: 400;
  font-style: normal;
}

.Silkscreen{
  font-family: 'Silkscreen', sans-serif;
}

.pagePadding{
  max-width: '85vw';
  margin: '0 auto'
}

.font-button{
  font-size: 1.5rem;
  font-family: 'Ma Shan Zheng', cursive;
  color: black;
  display: inline-flex;
}

.homeButton-nav-link{
  font-size: 1.5rem;
  font-family: 'Ma Shan Zheng', cursive;
  color: black;
  /* background-color: #56018D; */
  border-radius: 10px;
  display: inline-flex;
}

.nav-link-unselected{
  font-size: 1.5rem;
  font-family: 'Ma Shan Zheng', cursive;
  color: black;
  display: inline-flex;
}

.nav-link.active {
  text-decoration: underline;
  color: #56018D;
  font-size: 1.5rem;
  font-family: 'Ma Shan Zheng', cursive;
  display: inline-flex;
}

.MuiPaper-elevation4 {
  box-shadow: none;
}

/* <uniquifier>: Use a unique and descriptive class name
<weight>: Use a value from 100 to 900 */

.noto-sans-sc-intro {
  font-family: "Noto Sans SC", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
}
