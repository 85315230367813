.event-card {
    position: relative;
    display: inline-block;
    width: 340px; /* 增加卡片宽度 */
    height: 260px; /* 增加卡片高度 */
    overflow: hidden;
    border-radius: 10px; /* 可选：让图片有圆角效果 */
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    margin: 15px; /* 添加卡片间距 */
  }
  
  .event-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease; /* 鼠标悬停时添加缩放效果 */
  }
  
  .event-card:hover .event-image {
    transform: scale(1.1); /* 鼠标悬停时图片放大 */
  }
  
  .event-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* 半透明黑色背景 */
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    text-align: left;
    padding: 10px;
    opacity: 0;
    transition: opacity 0.3s ease;
  }
  
  .event-card:hover .event-overlay {
    opacity: 1;
  }
  
  .event-link {
    margin-top: 10px;
    padding: 8px 16px;
    background: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s ease;
  }
  
  .event-link:hover {
    background: #0056b3; /* 鼠标悬停时按钮颜色变深 */
  }
  
  .event-overlay h3 {
    font-size: 24px;
    margin: 10px 0;
  }
  