@keyframes animate {
    0%{
        transform: translateY(0) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }
    100%{
        transform: translateY(-1000px) rotate(720deg);
        opacity: 0;
        border-radius: 50%;
    }
}

@keyframes scrollLeftToRight {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }


.background {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    /* background: #4e54c8; */
    background: #EEEEEE;
    overflow: hidden;
    z-index: -1;
}
.background li {
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    /* background: rgba(255, 255, 255, 0.2); */
    background-color: rgba(137, 0, 255, 0.6);
    animation: animate 19s linear infinite;
}



.background li:nth-child(0) {
    left: 43%;
    width: 185px;
    height: 185px;
    bottom: -185px;
    animation-delay: 1s;
}
.background li:nth-child(1) {
    left: 58%;
    width: 168px;
    height: 168px;
    bottom: -168px;
    animation-delay: 4s;
}
.background li:nth-child(2) {
    left: 18%;
    width: 156px;
    height: 156px;
    bottom: -156px;
    animation-delay: 1s;
}
.background li:nth-child(3) {
    left: 20%;
    width: 175px;
    height: 175px;
    bottom: -175px;
    animation-delay: 7s;
}
.background li:nth-child(4) {
    left: 83%;
    width: 153px;
    height: 153px;
    bottom: -153px;
    animation-delay: 11s;
}
.background li:nth-child(5) {
    left: 58%;
    width: 101px;
    height: 101px;
    bottom: -101px;
    animation-delay: 15s;
}
.background li:nth-child(6) {
    left: 29%;
    width: 144px;
    height: 144px;
    bottom: -144px;
    animation-delay: 29s;
}
.background li:nth-child(7) {
    left: 32%;
    width: 148px;
    height: 148px;
    bottom: -148px;
    animation-delay: 14s;
}
.background li:nth-child(8) {
    left: 4%;
    width: 198px;
    height: 198px;
    bottom: -198px;
    animation-delay: 23s;
}
.background li:nth-child(9) {
    left: 75%;
    width: 174px;
    height: 174px;
    bottom: -174px;
    animation-delay: 35s;
}

.card-wrapper {
    display: flex;
    overflow: hidden;
    position: relative;
  }
  
  .card-gallery {
    display: flex;
    animation: scrollLeftToRight 600s linear infinite;
  }
  
  .dev-team-card {
    min-width: 230px; /* Adjust to the width of card */
    margin: 0 10px;   
    transition: transform 0.3s ease-in-out;
  }